// html,
// body {
//   height: 100%;
//   margin: 0;
//   padding: 0;
//   overflow-x: hidden;
// }

// body {
//   position: relative;
//   &::before {
//     background: url("../img/pflaster.jpg");
//     background-size: cover;
//     background-position: center;
//     content: " ";
//     display: block;
//     position: fixed;
//     z-index: -1;
//     opacity: 0.8;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//   }
// }

// a.no-underline {
//   text-decoration: none !important;
// }

// .bg-light {
//   background-color: #ffffffba !important;
// }

// @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
//   .bg-light, .bg-dark {
//     -webkit-backdrop-filter: blur(3px);
//     backdrop-filter: blur(3px);
//   }
// }

// .bg-light-solid-incl-watermark {
//   background: url("../img/pflaster.jpg");
//   background-size: cover;
//   background-position: center;
//   background-color: #ffffffba !important;
// }

// .clickable {
//   cursor: pointer;
// }

// .navbar {
//   .nav-item {
//     text-transform: uppercase;
//   }
// }

// .note-fontname,
// .note-color,
// .note-list
// {
//     display:none;
// }
.dropdown-style {
	min-width: 220px !important;
	a {
		color: #212529 !important;
		text-decoration: none;
	}
}
.note-icon-caret {
	display: none;
}
.sn-checkbox-open-in-new-window,
.sn-checkbox-use-protocol {
	display: none;
}
.fullscreen {
	background: white;
}

.tooltip {
	img {
		max-width: 100%;
	}
}
.modal-dialog {
	max-width: 700px;
}
.thumbnails {
	margin: 10px 0px;
	div > div {
		cursor: pointer;
		height: 150px;
		overflow: hidden;
		//padding: 5px;
		margin: 2px -13px;
		border: 1px solid gray;
		text-align: center;
		&:hover {
			opacity: 0.5;
		}
	}
	.thumbnail {
		max-width: 100%;
		max-height: 150px;
		border: 5px solid white;
	}
	span {
		margin-bottom: 10px;
		display: inline-block;
	}
}
.note-group-select-from-files {
	display: none;
}
.card {
	padding: 2px;
	&::before {
		content: 'einklappbarer Inhalt';
		position: absolute;
		right: 0px;
		top: -16px;
		font-size: 10px;
		color: gray;
	}
}
